<template>
  <navbar-hide class="sticky top-0" />

  <div class="snap-y relative snap-mandatory w-screen overflow-scroll">
    <div class="min-h-screen bg-white antialiased text-black">
      <main class="pt-16 lg:pt-24">
        <div
          class="flex flex-col lg:flex-row justify-between p-8 mx-auto max-w-screen-xl"
        >
          <article
            class="w-full lg:w-3/4 mx-auto max-w-2xl format format-sm sm:format-base lg:format-lg format-blue"
          >
            <header class="mb-4 lg:mb-6 not-format">
              <h1
                class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl"
              >
                {{ article.title }}
              </h1>
            </header>
            <div v-if="article.image">
              <img
                :src="article.image"
                :alt="article.title"
                class="mb-5 rounded-lg"
              />
            </div>
            <div v-html="article.content"></div>
          </article>

          <aside
            aria-label="Related articles"
            class="lg:ml-8 py-8 lg:py-0 lg:w-1/4"
          >
            <div class="px-4 lg:px-0">
              <h2 class="mb-8 text-2xl font-bold text-blue-600">
                Related articles
              </h2>
              <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-1">
                <article
                  v-for="related in paginatedArticles"
                  :key="related.id"
                  class="max-w-screen"
                >
                  <router-link
                    :to="{
                      name: 'Article',
                      params: { id: related.id, slug: related.slug },
                    }"
                  >
                    <img
                      :src="related.image"
                      class="mb-5 rounded-lg"
                      :alt="related.title"
                    />
                    <h2
                      class="mb-2 text-lg font-bold leading-tight text-gray-900"
                    >
                      {{ related.title }}
                    </h2>
                  </router-link>
                </article>
              </div>

              <!-- Pagination Controls -->
              <div class="flex justify-between mt-6">
                <button
                  @click="prevPage"
                  :disabled="currentPage === 1"
                  class="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                  class="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </aside>
        </div>
      </main>
      <Footer class="text-white" />
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import NavbarHide from "../components/NavbarHide.vue";
import { articles } from "../article.js";

export default {
  name: "Article",
  components: {
    Footer,
    NavbarHide,
  },
  data() {
    return {
      article: {},
      relatedArticles: [],
      meta: {
        title: "Article",
        metaTags: [],
      },
      currentPage: 1, // Menyimpan halaman saat ini
      itemsPerPage: 5, // Jumlah artikel per halaman
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.relatedArticles.length / this.itemsPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.relatedArticles.slice(start, end);
    },
  },
  watch: {
    "$route.params.slug": "fetchArticle",
  },
  methods: {
    fetchArticle() {
      const slug = this.$route.params.slug;
      this.article = articles.find((article) => article.slug === slug) || {};

      this.meta.metaTags = [
        {
          name: "description",
          content: this.article.description || "Description for the article",
        },
        {
          property: "og:description",
          content: this.article.description || "Description for the article",
        },
      ];

      this.updateMetaTags();
      this.fetchRelatedArticles();
      this.updateSlug();
      this.scrollToTop();
    },
    fetchRelatedArticles() {
      if (this.article && this.article.related) {
        this.relatedArticles = this.article.related.map((relatedId) =>
          articles.find((article) => article.id === relatedId)
        );
      } else {
        this.relatedArticles = [];
      }
      this.currentPage = 1; // Reset ke halaman pertama setelah fetch data baru
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    updateMetaTags() {
      Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
        (el) => el.parentNode.removeChild(el)
      );

      this.meta.metaTags
        .map((tagDef) => {
          const tag = document.createElement("meta");

          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
          });

          tag.setAttribute("data-vue-router-controlled", "");

          return tag;
        })
        .forEach((tag) => document.head.appendChild(tag));
    },
    updateSlug() {
      if (
        this.article &&
        this.article.slug &&
        this.$route.params.slug !== this.article.slug
      ) {
        this.$router.replace({
          name: "Article",
          params: { id: this.article.id, slug: this.article.slug },
        });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  beforeRouteEnter(to, from, next) {
    document.title = "Article";
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title = "Article";
    next();
  },
  created() {
    this.fetchArticle();
  },
};
</script>
