import { createApp } from "vue";
import App from "./App.vue";
import "./style.css";
import router from "../router";
import Vue3Lottie from "vue3-lottie";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import VueGtag from "vue-gtag";
// import { VueRecaptchaV3 } from "vue-recaptcha-v3";

library.add(faUserSecret);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(Vue3Lottie);
app.use(VueGtag, {
  config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_KEY },
});
// app.use(VueRecaptchaV3, {
//   siteKey: "6Ldz9SoqAAAAAN2qbzTQp-rqR8MPSBkludCFTWLD", // Masukkan site key reCAPTCHA V3 Anda di sini
// });
app.mount("#app");
