<template>
  <navbar-hide />

  <div class="snap-y snap-mandatory h-screen w-screen overflow-scroll">
    <div class="relative">
      <video
        ref="videoPlayer"
        class="w-full inset-0 h-screen snap-start object-cover"
        preload="none"
        muted
        playsinline
        autoplay
        @ended="replayVideo"
      >
        <source src="../assets/video/banner-vision.webm" type="video/webm" />
        <source src="../assets/video/banner-vision.mp4" type="video/mp4" />
      </video>

      <div
        class="absolute inset-x-0 bottom-0 flex items-center justify-center z-50"
      >
        <ChevronDownIcon class="text-white size-8 fixed animate-bounce mb-8" />
      </div>
    </div>
    <div
      class="snap-always snap-center bg-rapid-mobile bg-cover bg-center min-h-screen h-screen relative flex items-center justify-center"
    ></div>
    <div
      class="snap-always snap-center bg-[#0B0F1A] bg-cover bg-center min-h-screen h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian Atas (Mobile) -->
        <div
          class="flex items-center text-white justify-center w-full h-2/5 md:h-full md:w-1/2"
        >
          <div class="text-center md:text-left font-medium md-w:4/5">
            <p class="text-white text-lg md:text-4xl">
              Power at your fingertips.
              <b>Find, Monitor, and Control</b> Your Ride with
              <b>IMOTO.ev</b>
            </p>

            <p class="pt-10">Available on:</p>

            <div
              class="flex space-x-4 md:space-x-4 justify-center md:justify-start mt-4 md:mt-4"
            >
              <a
                href="https://apps.apple.com/id/app/imoto-ev/id6448974269"
                target="_blank"
              >
                <img
                  src="../assets/octa/app-store.png"
                  alt="Download on the App Store"
                  class="w-auto max-h-10"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.imoto&pcampaignid=web_share"
                target="_blank"
              >
                <img
                  src="../assets/octa/play-store.png"
                  alt="Download on the Play Store"
                  class="w-auto max-h-10"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- Bagian Kanan (Desktop) / Bagian Bawah (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            src="../assets/images/imoto-app.webp"
            alt="Product Image"
          />
        </div>
      </div>
    </div>

    <Footer class="snap-normal snap-center bg-center relative" />
  </div>
  <floating-carbon />
</template>

<script>
import Footer from "../components/Footer.vue";
import { Vue3Lottie } from "vue3-lottie";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import NavbarHide from "../components/NavbarHide.vue";
import FloatingCarbon from "../components/FloatingCarbon.vue";

export default {
  name: "VideoPlayer",
  components: {
    Footer,
    ChevronDownIcon,
    Vue3Lottie,
    NavbarHide,
    FloatingCarbon,
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs.videoPlayer.play(); // Autoplay the video when component is mounted
  },
  methods: {
    replayVideo() {
      this.$refs.videoPlayer.play(); // Replay the video
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
