<template>
  <navbar-hide />

  <div class="snap-y snap-mandatory md:h-screen w-screen overflow-scroll">
    <div class="relative">
      <video
        ref="videoPlayer"
        class="w-full inset-0 md:h-screen snap-start object-cover"
        preload="none"
        muted
        playsinline
        autoplay
        @ended="replayVideo"
        controls
      >
        <source src="../assets/video/about.mp4" type="video/mp4" />
      </video>

      <div
        class="absolute inset-x-0 bottom-0 flex items-center justify-center z-50"
      >
        <ChevronDownIcon class="text-white size-8 fixed animate-bounce mb-8" />
      </div>
    </div>

    <!-- section 1 test -->
    <div
      class="snap-always snap-center bg-white bg-cover bg-center md:min-h-screen md:h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row md:h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            src="../assets/images/about1.png"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 font-semibold text-lg">OUR STORY</p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">Who Are We?</h1>
            <p class="text-black mt-4">
              Imoto is an organisation run by Global Automotive enthusiast and
              professional who are passionate about Automotive and technology,
              who believe future of Indonesia Automotive Industry is EV
              and Imoto will continuously innovate to build Products and
              services which will make Carbon free mobility at a very low
              adoption cost.
            </p>
            <p class="text-black mt-4">
              With Racing Pedigree, imoto is building an Electric Bike FOR
              Indonesia, IN Indonesia and BY Indonesian where Riders can feel
              the adrenaline rushing the moment they sit on the bike and
              throttle the bike. Bikes will have imoto “FuTech” technology with
              no compromise on Performance which will change the way consumers
              have perceived EV.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- section 2 -->
    <div
      class="snap-always snap-center bg-white bg-cover bg-center min-h-screen h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row-reverse h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            src="../assets/images/about2.png"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 text-lg font-semibold">OUR THING</p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">Why We Exist</h1>
            <p class="text-black mt-4">
              We in imoto are determined to change lives by providing Zero
              Emission and High Performance Electric Mobility. We are building
              technologies for rapid decarbonization of mobility.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- section 3 -->
    <div
      class="snap-always snap-center bg-white bg-cover bg-center md:min-h-screen md:h-screen relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>

      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row md:h-screen w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            src="../assets/images/about3.png"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 font-semibold text-lg">OUR MISSION</p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">
              What Are We Doing?
            </h1>
            <p class="text-black mt-4">
              imoto was established in 2021 as a full-fledged automotive OEM
              with R&D in Indonesia and India, focussing on Electrical mobility.
              We believe that by 2035 imoto will be a Zero Carbon company and
              our products will deliver what we envisage and commit today and
              team with racing pedigree is working with a clear motto of
              providing the best of the EV technology to the consumers, at the
              most affordable cost. imoto with its R&D center in Indonesia and
              India is working on:
            </p>
            <div class="pt-4 max-w-lg mx-auto">
              <!-- List 1 -->
              <div class="flex items-start space-x-3 mb-4">
                <i class="fas fa-check text-green-500 text-xl"></i>
                <p class="text-gray-800 text-lg">
                  imoto controller technology.
                </p>
              </div>

              <!-- List 2 -->
              <div class="flex items-start space-x-3 mb-4">
                <i class="fas fa-check text-green-500 text-xl"></i>
                <p class="text-gray-800 text-lg">
                  Native EV Frame Design from Ground Zero.
                </p>
              </div>

              <!-- List 3 -->
              <div class="flex items-start space-x-3 mb-4">
                <i class="fas fa-check text-green-500 text-xl"></i>
                <p class="text-gray-800 text-lg">
                  Innovative Frame and Body Material.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section 4 -->
    <div
      class="snap-always snap-start bg-white bg-cover bg-center relative flex items-center justify-center overflow-hidden"
    >
      <div
        class="absolute top-0 left-0 w-[500px] h-[500px] bg-blue-500 rounded-full blur-3xl opacity-10 transform -translate-x-1/2 -translate-y-1/2"
      ></div>

      <div
        class="absolute bottom-0 right-0 w-[500px] h-[500px] bg-pink-500 rounded-full blur-3xl opacity-10 transform translate-x-1/2 translate-y-1/2"
      ></div>
      <div
        class="container mx-auto px-6 md:px-16 relative py-16 flex flex-col md:flex-row-reverse w-screen"
      >
        <!-- Bagian Kiri (Desktop) / Bagian atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-full md:w-4/5 md:pt-20"
            src="../assets/images/about4.png"
            alt="Product Image"
          />
        </div>
        <!-- Bagian Kanan (Desktop) / Bagian bawah (Mobile) -->
        <div
          class="flex text-black md:justify-center md:items-center w-full md:h-full md:w-1/2"
        >
          <div class="text-left md:text-left font-medium md-w:4/5">
            <p class="text-black mt-4 text-lg font-semibold">OUR TECHNOLOGY</p>

            <h1 class="pt-4 text-3xl font-bold md:text-6xl">
              Our Technology Products
            </h1>
            <p class="text-black mt-4">
              Since imoto’s establishment, imoto has released their own
              products, hardware and software. Those products are:
            </p>

            <div class="pt-4 max-w-lg mx-auto">
              <!-- List 1 -->
              <div class="mb-6">
                <p class="text-gray-800 text-xl font-bold">1. Imotoshare</p>
                <p class="text-gray-600 pl-4 text-base mt-2">
                  is a service provider platform for smart electric mobility
                  (MaaS) that offers electric motorcycle subscription services.
                  The Imotoshare platform is very easy and safe to use, offering
                  high performance, long battery life, and better riding comfort
                  compared to conventional motorcycles, making it the best
                  choice to accommodate your transportation needs.
                </p>
              </div>

              <!-- List 2 -->
              <div class="mb-6">
                <p class="text-gray-800 text-xl font-bold">2. imoto Cloud</p>
                <p class="text-gray-600 pl-4 text-base mt-2">
                  is a cloud based fully inhoused developed fleet management
                  system and imoto App technology to provide customers with big
                  data for informative and interactive driving experiences.
                </p>
              </div>

              <!-- List 3 -->
              <div class="mb-6">
                <p class="text-gray-800 text-xl font-bold">
                  3. imoto "Rapid" fast charge battery
                </p>
                <p class="text-gray-600 pl-4 text-base mt-2">
                  Team imoto has developed a unique "Rapid" Fast-Charging
                  battery pack which can charge Battery from 20% to 80% in just
                  30 mins. This is the first of its kind in Indonesia developed
                  inhouse by a young energetic team in just 2 years of hard and
                  dedicated work. This will revolutionize the EV market in
                  Indonesia and the acceptability of EV will increase rapidly.
                  This allows Riders to experience cutting-edge Battery
                  Technology built fully in Indonesia. So .. Charge Faster,
                  Speed up and Ride Longer with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer class="snap-normal snap-center bg-center relative" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import { Vue3Lottie } from "vue3-lottie";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import NavbarHide from "../components/NavbarHide.vue";
import FloatingCarbon from "../components/FloatingCarbon.vue";

export default {
  name: "VideoPlayer",
  components: {
    Footer,
    ChevronDownIcon,
    Vue3Lottie,
    NavbarHide,
    FloatingCarbon,
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs.videoPlayer.play(); // Autoplay the video when component is mounted
  },
  methods: {
    replayVideo() {
      this.$refs.videoPlayer.play(); // Replay the video
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
